// @mui
import { RadioGroup } from '@mui/material';
//
import { useSettingsContext } from '../SettingsContext';
import { StyledCard, StyledWrap, MaskControl } from '../styles';
import Iconify from '../../iconify/Iconify';

// ----------------------------------------------------------------------

const OPTIONS = ['light', 'dark'] as const;

export default function ModeOptions() {
  const { themeMode, onChangeMode } = useSettingsContext();

  return (
    <RadioGroup name="themeMode" value={themeMode} onChange={onChangeMode} >
      <StyledWrap>
        {OPTIONS.map((mode) => (
          <StyledCard key={mode} selected={themeMode === mode} color='warning'>
            {
              mode === 'light' ?
                <Iconify icon="material-symbols:light-mode" width={33} color='warning' /> :
                <Iconify icon="material-symbols:dark-mode" width={33} color='warning' />
            }
            <MaskControl value={mode} />
          </StyledCard>
        ))}
      </StyledWrap>
    </RadioGroup>
  );
}
