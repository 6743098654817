import { Theme } from '@mui/material/styles';
//
import Card from './Card';
import Chip from './Chip';
import Dialog from './Dialog';
import Drawer from './Drawer';
import Menu from './Menu';
import Link from './Link';
import Lists from './List';
import Paper from './Paper';
import Input from './Input';
import Radio from './Radio';
import Slider from './Slider';
import Button from './Button';
import Switch from './Switch';
import Select from './Select';
import Tooltip from './Tooltip';
import Popover from './Popover';
import Skeleton from './Skeleton';
import Progress from './Progress';
import Checkbox from './Checkbox';
import Typography from './Typography';
import ButtonGroup from './ButtonGroup';
import ToggleButton from './ToggleButton';
import ControlLabel from './ControlLabel';
import LoadingButton from './LoadingButton';

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme: Theme) {
  return Object.assign(
    Chip(theme),
    Card(theme),
    Dialog(theme),
    Drawer(theme),
    Menu(theme),
    Link(theme),
    Input(theme),
    Radio(theme),
    Lists(theme),
    Paper(theme),
    Switch(theme),
    Select(theme),
    Button(theme),
    Slider(theme),
    Drawer(theme),
    Tooltip(theme),
    Popover(theme),
    Checkbox(theme),
    Skeleton(theme),
    Progress(theme),
    Typography(theme),
    ButtonGroup(theme),
    ControlLabel(theme),
    ToggleButton(theme),
    LoadingButton(theme)
  );
}
