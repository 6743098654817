import Body from "./Body";
import Header from "./Header";
import { Outlet } from 'react-router-dom';

export default function MainLayout() {
  return (
    <>
      <Header />
      <Body>
        <Outlet />
      </Body>
    </>
  )
}
