import { BrowserRouter } from 'react-router-dom';
import Router from './routes/routes';
import { Provider } from 'react-redux'
import { store } from './state/store';
import { useEffect } from 'react';
import { useServiceWorker } from './useServiceWorker';
import { SnackbarProvider, useSnackbar } from './components/common/snackbar';
import { ThemeSettings, SettingsProvider } from './components/common/settings';
import ThemeProvider from './theme';


function App() {
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  const { enqueueSnackbar } = useSnackbar();
  // decides when to show the toast
  useEffect(() => {
    if (showReload && waitingWorker) {
      enqueueSnackbar('refresh')
    } else enqueueSnackbar('no new version');
  }, [waitingWorker, showReload, reloadPage]);

  return (
    <Provider store={store}>
      <SettingsProvider>
        <BrowserRouter>
          <ThemeProvider>
            <ThemeSettings>
              <SnackbarProvider>
                <Router />
              </SnackbarProvider>
            </ThemeSettings>
          </ThemeProvider>
        </BrowserRouter>
      </SettingsProvider>
    </Provider>
  );
}

export default App;
