import { Button, Card, Container, Grid, TextField, Typography } from "@mui/material";
import { useSettingsContext } from "../components/common/settings";
import { Upload } from "../components/common/upload";
import { useCallback, useState } from "react";
import { getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { initializeApp } from "firebase/app";
import { useCreateReportMutation } from "../services/reports";
import { useSnackbar } from "../components/common/snackbar";


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STOREGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGEING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);


export default function HelpPage() {
  const { themeStretch } = useSettingsContext();
  const { enqueueSnackbar } = useSnackbar();

  const [create] = useCreateReportMutation()

  const [files, setFiles] = useState<(File)[] | string[] | any>([]);
  const [description, setDescription] = useState('')


  const imageHandleDrop = useCallback((acceptedFiles: File[]) => {
    const newFiles = acceptedFiles.map((file: any) => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
      return file
    })
    setFiles([...files, ...newFiles]);
  },
    [files]
  );

  const handleRemoveFile = (inputFile: File | string) => {
    const filtered = files.filter((file: any) => file !== inputFile);
    setFiles(filtered);
  };

  const submit = () => {
    const reportFiles = files.map((file: any) => {
      const storageRef = ref(storage, 'reports/' + file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);
      return { name: file.name, fbUrl: storageRef.fullPath, path: file.path }
    })
    create({ description, docs: reportFiles })
    setDescription("")
    setFiles([])
    enqueueSnackbar("Ваша запрос отправлен. Спасибо большое за вашу помощь", {
      variant: "info",
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      transitionDuration: 1000
    })
    enqueueSnackbar("Спасибо большое за вашу помощь!", {
      variant: "success",
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      transitionDuration: 500
    })
  };

  return (
    <>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Card sx={{ p: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <form style={{ padding: 1 }}>
                <Typography variant="h6">Нашли обишку?</Typography>
                <TextField
                  multiline
                  placeholder="Опишите ошибку (в приложении или в тексте песен и т.д.)?"
                  minRows={3}
                  fullWidth
                  value={description}
                  onChange={(value: any) => setDescription(value.target.value)}
                  sx={{ mb: 1 }}
                />
                <Upload
                  multiple
                  thumbnail
                  files={files}
                  onDrop={imageHandleDrop}
                  onRemove={handleRemoveFile}
                  sx={{ mb: 1 }}
                />
                <Button type="button" variant="outlined" size="large" onClick={submit} fullWidth> Отправить</Button>
              </form>

            </Grid>
          </Grid>

        </Card>
      </Container>
    </>
  )
}
