import { AppBar, Toolbar, IconButton, useTheme, Typography, Drawer, Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { useSettingsContext } from '../components/common/settings/SettingsContext';
import useOffSetTop from '../hooks/useOffSetTop';
import useResponsive from '../hooks/useResponsive';
import { HEADER, NAV } from '../config-global';
import { bgBlur } from '../utils/cssStyles';
import Iconify from '../components/common/iconify';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";


export default function Header() {
  const navigate = useNavigate();

  const theme = useTheme();
  const { themeLayout } = useSettingsContext();

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const isDesktop = useResponsive('up', 'lg');

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  const [opneDrawer, setOpenDraewr] = useState(false)

  const toggleDrawer = (open: boolean, event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpenDraewr(open);
  };

  const redirectToSongList = () => {
    navigate('/app/songs/list');
  }

  const redirectSettings = () => {
    navigate('/app/settings');
  }

  const redirectHelp = () => {
    navigate('/app/help');
  }

  return (
    <>
      <AppBar
        sx={{
          boxShadow: 'none',
          height: HEADER.H_MOBILE,
          zIndex: theme.zIndex.appBar + 1,
          ...bgBlur({
            color: theme.palette.background.default,
          }),
          transition: theme.transitions.create(['height'], {
            duration: theme.transitions.duration.shorter,
          }),
          ...(isDesktop && {
            // width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
            height: HEADER.H_DASHBOARD_DESKTOP,
            ...(isOffset && {
              height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            }),
            ...(isNavHorizontal && {
              width: 1,
              bgcolor: 'background.default',
              height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
              borderBottom: `dashed 1px ${theme.palette.divider}`,
            }),
            ...(isNavMini && {
              width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
            }),
          }),
        }}
      >
        <Toolbar
          sx={{
            height: 1,
            px: { lg: 5 },
            background: 'black'
          }}
        >
          <IconButton onClick={(event: any) => toggleDrawer(true, event)} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="material-symbols:menu" color="white" width={30} />
          </IconButton>
          <Typography variant='h5' fontWeight={600} color="white">HAVERIM</Typography>
          <Drawer
            open={opneDrawer}
            onClose={(event: any) => toggleDrawer(false, event)}
            variant='temporary'
          >
            <Box
              sx={{ width: 250 }}
              role="presentation"
              onClick={(event: any) => toggleDrawer(false, event)}
              onKeyDown={(event: any) => toggleDrawer(false, event)}
            >
              <List>
                <ListItem disablePadding>
                  <ListItemButton onClick={redirectToSongList}>
                    <ListItemIcon>
                      <Iconify icon="entypo:folder-music" width={30} />
                    </ListItemIcon>
                    <ListItemText primary={"Песни"} />
                  </ListItemButton>
                </ListItem>
                {/* <ListItem disablePadding >
                  <ListItemButton>
                    <ListItemIcon>
                      <Iconify icon="vaadin:pin-post" width={30} />
                    </ListItemIcon>
                    <ListItemText primary={"Публикации"} />
                  </ListItemButton>
                </ListItem> */}
              </List>
              <Divider />
              <List>
                <ListItem disablePadding>
                  <ListItemButton onClick={redirectSettings}>
                    <ListItemIcon >
                      <Iconify icon="material-symbols:settings-outline" width={30} />
                    </ListItemIcon>
                    <ListItemText primary={"Настройки"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton onClick={redirectHelp}>
                    <ListItemIcon>
                      <Iconify icon="material-symbols:help-center-outline" width={30} />
                    </ListItemIcon>
                    <ListItemText primary={"Помощь"} />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </Drawer>
        </Toolbar>
      </AppBar>
    </>
  )
}
