import { ListItem, Typography } from "@mui/material"
import { ISong } from "../../types/Song"
import { Link } from "react-router-dom";
import { useSettingsContext } from "../common/settings";

interface IProps {
  song: ISong
}
export const SongListItem = ({ song }: IProps) => {
  const { themeMode } = useSettingsContext();
  return (
    <Link to={`/app/songs/${song._id}`} style={{ textDecoration: "none", color: themeMode === "dark" ? "ghostwhite" : "black" }}>
      <ListItem sx={{ p: 0, pt: '7px', pb: '7px', boxShadow: '0px 0.5px 0px rgba(255, 207, 10, 0.5)' }} >
        <Typography fontWeight={600} fontSize={18} textAlign="right" mr={1} width={22}>{song.songNumber}</Typography>
        <Typography
          fontSize={20}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: '330px',
            textOverflow: 'ellipsis'
          }}
        >{song.name}</Typography>
      </ListItem>
    </Link>
  )
}
