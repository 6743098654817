import { ISong } from "../types/Song";
import { api } from "./api";

export const songsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllSongs: builder.query({
      query: () => `/songs`,
      providesTags: () => [{ type: "Songs", id: "List" }]
    }),
    getSong: builder.query<ISong, string>({
      query: (id) => `/songs/${id}`,
      providesTags: (_result, _err, id) => [{ type: 'Songs', id }],
    }),
  }),
})

export const {
  useGetAllSongsQuery,
  useGetSongQuery
} = songsApi
