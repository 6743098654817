import { Box, Container, IconButton, InputAdornment, LinearProgress, List, Stack, TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import Iconify from "../components/common/iconify";
import { useSettingsContext } from "../components/common/settings/SettingsContext";
import { SongListItem } from "../components/song/SongListItem";
import { useGetAllSongsQuery } from "../services/songs";
import { ISong, ISongItem, ISongItemLine } from "../types/Song";
import { useSnackbar } from "../components/common/snackbar";


export default function SongListPage() {
  const { themeStretch, themeMode } = useSettingsContext();
  const { enqueueSnackbar } = useSnackbar();

  const { data: songs } = useGetAllSongsQuery({})
  const [songList, setSongList] = useState<ISong[]>(JSON.parse(localStorage.getItem('songList') as string) || [])
  const [searchTerm, setSearchTerm] = useState('')


  useEffect(() => {
    if (songs) {
      setSongList(songs);
      localStorage.setItem('songList', JSON.stringify(songs))
      console.log('song list updated');
      setSongList(songs)
    }
  }, [songs, enqueueSnackbar])

  const searchSongs: ISong[] = useMemo(() => {
    if (!searchTerm) return songList;
    return songList.filter((song: ISong) => song.name.toLowerCase().includes(searchTerm)
      || song.songNumber.toString() === searchTerm
      || song.items?.some((item: ISongItem) => item.lines?.some((line: ISongItemLine) => line.text.toLowerCase().includes(searchTerm))))
  }, [searchTerm, songList])

  return (
    <>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Box
          sx={{
            width: '100%',
            position: 'fixed',
            zIndex: 1,
            display: 'flex',
          }}>
          <TextField
            placeholder="Поиск..."
            fullWidth
            type="text"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value.toLowerCase())}
            sx={{
              width: '100%',
              maxWidth: {
                xs: 370,
                sm: 'sm',
                md: 'md',
                lg: 'lg'
              },
              mb: 1,
              background: themeMode === 'dark' ? "#212B36" : 'white',
              opacity: 0.92
            }}

            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" color="primary" >
                    {searchTerm && <Iconify icon="mdi:clear-bold" color="black" width={30} onClick={() => { setSearchTerm('') }} />}
                  </IconButton>
                </InputAdornment>
              ),
            }}

          />
        </Box>
        <Box>
          <List sx={{ p: 0, pt: 7 }}>
            {
              !songList.length &&
              <Stack sx={{ width: '50%', color: 'grey.500', m: '0 auto', mt: '50%' }} spacing={2}>
                <LinearProgress color="warning" />
              </Stack>
            }
            {
              searchSongs && searchSongs.map((song) => <SongListItem key={song._id} song={song} />)
            }
          </List>
        </Box>
      </Container>
    </>
  )
}
