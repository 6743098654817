import { api } from "./api";


export const reportsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createReport: builder.mutation({
      query: (data) => ({
        url: `/reports/create`,
        method: 'POST',
        body: data
      })
    }),
  })
})

export const {
  useCreateReportMutation
} = reportsApi
