import { Navigate, useRoutes } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import SongListPage from "../pages/SongListPage";
import ViewSongPage from "../pages/ViewSongPage";
import SettingsPage from "../pages/SettingsPage";
import HelpPage from "../pages/HelpPage";


export default function Router() {
  return useRoutes([
    {
      path: 'app',
      element: <MainLayout />,
      children: [
        {
          path: 'songs',
          children: [
            { element: <Navigate to="/app/songs/list" replace />, index: true },
            { path: 'list', element: <SongListPage /> },
            { path: ':id', element: <ViewSongPage /> },
          ]
        },
        {
          path: 'settings', element: <SettingsPage />
        },
        {
          path: 'help', element: <HelpPage />
        }
      ]
    },
    { path: '/', element: <Navigate to={"/app/songs/list"} replace /> },
    { path: '*', element: <Navigate to="/" replace /> },
  ])
}
