import { Box, Card, CardContent, CardHeader, Container, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { useSettingsContext } from "../components/common/settings/SettingsContext";
import ModeOptions from "../components/common/settings/drawer/ModeOptions";

export default function SettingsPage() {
  const { themeStretch } = useSettingsContext();

  const onChangeFontFamily = (value: string) => {
    localStorage.setItem('font', value)
  }

  return (
    <>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Box
          rowGap={3}
          columnGap={1}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(3, 1fr)',
            md: 'repeat(2, 1fr)'
          }}
        >
          <Card>
            <CardHeader title="Тема" />
            <CardContent>
              <ModeOptions />
            </CardContent>
          </Card>
          <Card>
            <CardHeader title="Шрифт" />
            <CardContent>
              <RadioGroup
                aria-labelledby="font-radio-buttons-group-label"
                defaultValue="Public Sans,sans-serif"
                name="font-radio-buttons-group"
                onChange={(event) => onChangeFontFamily(event.target.value)}
              >
                <FormControlLabel value="Public Sans,sans-serif" control={<Radio />} label={<Typography sx={{ fontFamily: 'Public Sans,sans-serif', fontSize: 17 }}>Хаверим</Typography>} />
                <FormControlLabel value="Comic Sans MS" control={<Radio />} label={<Typography sx={{ fontFamily: 'Comic Sans MS', fontSize: 17 }}>Хаверим</Typography>} />
                <FormControlLabel value="NotoSerifTC Bold" control={<Radio />} label={<Typography sx={{ fontFamily: 'NotoSerifTC Bold', fontSize: 17 }}>Хаверим</Typography>} />
                <FormControlLabel value="InterTight Medium" control={<Radio />} label={<Typography sx={{ fontFamily: 'InterTight Medium', fontSize: 17 }}>Хаверим</Typography>} />
              </RadioGroup>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </>
  )
}
